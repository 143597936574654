import React, { useState } from "react";
import Verification from "./modal/Verification";

const PrivacyPolicy = () => {
  const [verificationModal, setVerificationModal] = useState(false);

  const handleEraseData = () => {
    setVerificationModal(true);
  };

  return (
    <>
      <div className="container mx-auto my-5 flex justify-center text-white">
        <div className="w-full flex flex-col gap-3">
          <h1 className="text-4xl mb-4 text-center">PRIVACY & POLICY</h1>
          <div className="px-2">
            <h2 className="text-xl mb-2">Policy Regarding Privacy Peterly</h2>
            <div>
              <p>
              We appreciate your choice of Peterly, a social network for dogs. We are dedicated to protecting your privacy and value the trust you have put in us. When you use our services, this Privacy Policy will describe how we will collect, use, disclose, and store the information that pertains to you. By using Peterly, you indicate that you agree to the terms described in this policy.
              </p>
              <p>
                We obtained the following data: account information, section a.

We collect the most basic account information from you when you join up with Peterly. This contains your username, email address, and password.
b. Features of the Pet Profile:

Our staff will collect information about your pet, such as their name, breed, age, and photos, to create a profile for them.
Content created by users:

On Peterly, we save the content you create, upload, or share with others. This material consists of text, videos, and images. Data Related to Use:

We collect information about your interactions with Peterly, such as the activities you participate in, the preferences you choose, and the settings you pick.
Device Information, Section e:

We may collect information about the device you use to access Peterly, such as its kind, operating system, and browser.
How We Can Use Your Information:
1. Providing and improving the quality of services

We use your information to deliver, maintain, and develop Peterly. This involves customizing your experience and developing new features.
b. Exchange of messages:

We may use your email address to give you important updates, notifications, or promotional information about Peterly. c. User Support

Your information allows us to provide more effective customer service and react to queries or complaints.
Share your information with others.
a. Profiles for pets:

It is possible to see pet profiles freely. All of the information you publish in your pet profile, including photographs and specifications, will be visible to other Peterly members.
Content created by users:

Other users may view all of the stuff you publish to Peterly. Take cautious with the information you provide in the comments and descriptions of your postings.
b. Service Providers Outside of the Company:

We may release your information to third-party service providers in order to help Peterly with its operational and technical features.
d. Compliance with the law:

We may release personal information if required by law, in response to a legal request, or to protect our rights, users, or other persons.
Safeguarding:

Your information is protected against unauthorized access, disclosure, alteration, and destruction via the use of industry-standard security procedures.
Your options are:
1. Settings for the Profile

Within your Peterly account, you may change your privacy settings to control the availability of your pet's profile and content.
Email Preferences, Section B:

You may opt out of receiving promotional messages by updating your email selections in your account's settings.
Age Restrictions: [Note]

Peterly can only be consumed by children above the age of eight. Peterly does not allow users under the age of eight to create accounts on its site. Parents or legal guardians are responsible for monitoring the usage of Peterly by children under the age of 13.
Prohibition of posting photographs of humans:

Users are strictly prohibited from posting pictures on Peterly that feature images of identifiable individuals. This category includes photos with faces, personal identities, or any other recognizable human traits, although it is not limited to them.
Changes to the Privacy Policy:

This Privacy Policy may be subject to regular modifications from us. Users will be notified of any substantial changes, and continued use of Peterly is assumed to imply acceptance of the new policy.
Get in touch with us

If you have any questions or concerns concerning the privacy of your Peterly account, please contact us at privacy@peterly.com.
When you use Peterly, you agree to the practices mentioned in this Privacy Policy. Your trust and participation in our pet-loving community is much appreciated.
              </p>
            </div>
          </div>
          <div className="w-full">
            <h2 className="text-xl mb-2">Remove UserData</h2>
            <p>
              Social Media Examiner, and our subsidiaries and affiliates (the
              “Related Companies”), may use your personal information
            </p>
            <button
              className="mt-2 p-2 bg-blue-400 rounded text-white"
              onClick={handleEraseData}
            >
              Erase All Data
            </button>
          </div>
        </div>
      </div>

      {verificationModal ? (
        <Verification open={verificationModal} setOpen={setVerificationModal} />
      ) : null}
    </>
  );
};

export default PrivacyPolicy;
