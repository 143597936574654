import React, { useEffect } from "react";
import Header from "../components/Header";
import { ToastContainer } from "react-toastify";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import bg from "../assets/images/Background.png";

const Layout = () => {
  return (
    <>
      <div
        className="bg-cover bg-center min-h-screen  flex flex-col"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <Header />
        <div className="flex-1">
          <div className="min-h-[66vh] md:min-h-[67vh] max-h-[67vh] md:max-h-[70vh] overflow-y-scroll">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
};

export default Layout;
