import { Route, Routes } from "react-router-dom";
import { Paths } from "./path";

import PrivacyPolicy from "../components/PrivacyPolicy";
import Layout from "../pages/Layout";
import Home from "../components/Home";

const AppRoute = () => {
  return (
    <Routes>
      <Route path={Paths.home} element={<Home />} />
      <Route element={<Layout />}>
        <Route path={Paths.privacy} element={<PrivacyPolicy />} />
      </Route>
    </Routes>
  );
};

export default AppRoute;
