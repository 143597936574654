import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ios from "../assets/images/ios.png";
import android from "../assets/images/playstore.png";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState("");
  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    setCurrentYear(currentYear);
  }, []);
  return (
    <div className="flex-shrink-0">
      <footer className="bg-black-opacity-50 rounded-lg shadow">
        <div className="w-full max-w-screen-xl mx-auto p-2 md:py-4">
          <div className="flex items-center justify-center">
            <ul className="flex flex-wrap items-center mb-2 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <Link className="hover:underline me-4 md:me-6">About</Link>
              </li>
              <li>
                <Link
                  to={"/privacy-policy"}
                  className="hover:underline me-4 md:me-6"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="hover:underline me-4 md:me-6">Licensing</Link>
              </li>
              <li>
                <Link className="hover:underline">Blog</Link>
              </li>
            </ul>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="flex gap-2 justify-center mb-2">
            {/* <div> */}
            <Link
              to={
                "https://play.google.com/store/apps/details?id=com.ninextech.peterly"
              }
            >
              <img src={android} className="w-24 md:w-40" alt="os" />
            </Link>
            <img src={ios} className="w-24 md:w-40" alt="os" />
            {/* </div> */}
          </div>
          <div className="flex justify-center">
            <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © {currentYear} &nbsp;
              <Link to={"/"} className="hover:underline">
                Peterly™
              </Link>
              . All Rights Reserved.
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
