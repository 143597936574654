import React from "react";
import { Link } from "react-router-dom";
import mobile1 from "../assets/images/mobile-1.png";
import mobile2 from "../assets/images/mobile-2.png";
import pawIcon from "../assets/images/Paw icon -pink.png";
import Peterly from "../assets/images/peterly-pink.png";
import android from "../assets/images/play-store.png";
import appStore from "../assets/images/app-store.png";

const Home = () => {
  return (
    <div className="w-screen h-screen bg-[#EF7A85] overflow-y-hidden">
      <div className="xl:container w-full h-full xl:mx-auto">
        <div className="flex h-full justify-center">
          {/* Left Section  */}
          <div className="w-full lg:w-1/2 hidden lg:block">
            <div className="flex items-center justify-center w-full h-full relative">
              <img
                src={mobile2}
                alt="mobile1"
                className="absolute inset-x-[3rem] 2xl:inset-x-[10rem]"
              />
              <img
                src={mobile1}
                alt="mobile1"
                className="absolute  inset-x-[14rem] 2xl:inset-x-[22rem] "
              />
            </div>
          </div>

          {/* Right Section  */}
          <div className="w-full h-full lg:w-1/2  flex justify-center">
            <div className="bg-right w-full xl:w-3/4 2xl:w-2/3 p-[30px] lg:p-[50px]">
              {/* navBar Area  */}
              <div className="flex justify-center items-start gap-[15px] sm:gap-[20px] lg:gap-[30px] 2xl:gap-[47px] shrink-0 lg:mx-4">
                <Link
                  className="text-gray-600 font-poppins text-sm sm:text-base font-normal leading-normal capitalize whitespace-no-wrap"
                  to={"/privacy-policy"}
                >
                  Privacy policy
                </Link>
                <Link className="text-gray-600 font-poppins text-sm sm:text-base font-normal leading-normal capitalize whitespace-no-wrap">
                  Log In
                </Link>
                <Link className="text-gray-600 font-poppins text-sm sm:text-base font-normal leading-normal capitalize whitespace-no-wrap">
                  Peterly Business
                </Link>
              </div>
              {/* Center Body  */}
              <div className="flex items-center justify-center h-full w-full ">
                <div>
                  <div className="flex justify-center items-center flex-col w-full gap-6">
                    <img
                      src={pawIcon}
                      alt="pawIcon"
                      className="w-[45px] sm:w-[60px]"
                    />
                    <img
                      src={Peterly}
                      alt="peterly"
                      className="w-[155px] sm:w-[206px]"
                    />
                  </div>
                  <div className="mt-5">
                    <p className="text-[#7F78C5] font-aclonica md:text-xl font-normal capitalize text-center">
                      your pet’s digital world
                    </p>
                  </div>
                  <div className="mt-6">
                    <p className="text-[#353535] font-poppins md:text-lg font-semibold  text-center">
                      Get our App
                    </p>
                  </div>
                  <div className="flex gap-2 md:gap-4 mt-8">
                    <Link
                      to={
                        "https://play.google.com/store/apps/details?id=com.ninextech.peterly"
                      }
                    >
                      <div className="rounded-full bg-black w-[120px] h-[40px] sm:w-[170px] sm:h-[50px] lg:w-[200px] lg:h-[65px] flex-shrink-0 relative">
                        <img
                          src={android}
                          alt="android"
                          className="absolute lg:inset-y-4 lg:left-6 lg:w-8 sm:left-3 inset-y-2 sm:w-7 w-5  left-2"
                        />
                        <span className="text-white font-poppins text-sm sm:text-base font-medium capitalize absolute lg:inset-y-5 lg:right-10 inset-y-2 sm:inset-y-3 right-2 sm:right-6">
                          Google Play
                        </span>
                      </div>
                    </Link>
                    <Link to={""}>
                      <div className="rounded-full bg-black w-[120px] h-[40px] sm:w-[170px] sm:h-[50px] lg:w-[200px] lg:h-[65px]  flex-shrink-0 relative">
                        <img
                          src={appStore}
                          alt="appstore"
                          className="absolute lg:inset-y-3 lg:left-6 lg:w-10 sm:left-3 left-2 inset-y-2 sm:w-9 w-6 "
                        />
                        <span className="text-white font-poppins text-sm sm:text-base font-medium capitalize absolute lg:inset-y-5 lg:right-10 inset-y-2 sm:inset-y-3 right-4 sm:right-6">
                          App Store
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
